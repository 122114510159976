import React, { useState,useEffect } from 'react';
import { Card, Col, Row, Form, Modal, Button, Badge } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { ResponseFromServer } from 'helpers/utils';

const ProductHeader = (props) => {
  const [productDetails ,setProductDetails] =useState([])

  useEffect(() => {
    handleProductDetails();
  }, []);


  const handleProductDetails = () => {
    ResponseFromServer('products/product-list', `product/list`).then((res) => {
      setProductDetails(res.data);
      props.setProductFilter(res.data);
    });
  };




  return (
    <>

    </>
  );
};

export default ProductHeader;
