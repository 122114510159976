import React, { useState, useEffect } from 'react';
import { Tab ,Nav,Button,Table,Form,Row, Col, Card, Carousel, Badge,Modal } from 'react-bootstrap';
import { ResponseFromServer,PutToServer,sendData } from 'helpers/utils';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import StarRating from 'components/common/StarRating';
import IconButton from 'components/common/IconButton';
import { useSearchParams } from 'react-router-dom';



const ProductName = () => {
  const [productDetail, setProductDetail] = useState({});
  const [loading, setLoading] = useState(true);
  const [carouselImages, setCarouselImages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showColorModal, setShowColorModal] = useState(false);
  const [numberOfColors, setNumberOfColors] = useState(0);


  const [formData, setFormData] = useState({
    name: '',
    status: 'ACTIVE',
    colorOptions:'',
    offerPrice: '',
    basePrice:'',
    clientRating: '',
    fabric: '',
    gsm: '',
    mrp: '',
    ratingCount: '',
    moq: '',
    availableSizes: [],
    productDescription: [],
    customizeOptions: [],
    moreInfo: [],
  });
  const [colorData, setColorData] = useState({
    name: '',
    status: 'ACTIVE',
    color:[],
    colorName: '',
    colorIndex: '',
    shortName: '',
  });


  const [searchParams] = useSearchParams();
 const pageName = searchParams.get('productName');

 useEffect(() => {
    if (pageName) {
      getProductDetails(pageName);
    }
  }, [pageName]);

  const path = window.location.pathname;
  const search = window.location.search;
  const fullPath = path + search;

  const getProductDetails = (pageName) => {
      setLoading(true);
      ResponseFromServer(fullPath.replace("/", ""), `product?productName=${pageName}`)
        .then((res) => {
          if (res?.data?.productDetails) {
            setProductDetail(res.data.productDetails);
            const productOptions = res.data.productDetails.productOptions?.[0];
            const defaultImages = productOptions?.displayImages?.length
              ? productOptions.displayImages
              : productOptions?.images || [];
            setCarouselImages(defaultImages);

            const colorOptions =
              res.data.productDetails.productOptions?.map((option) => option.name) || [];
            const uniqueColors = new Set(colorOptions.map((color) => color.toLowerCase())); // Case-insensitive comparison
            const numberOfColors = uniqueColors.size;
            setNumberOfColors(numberOfColors);
          } else {
            console.error('Product details not found in response');
          }
          setLoading(false);
        })
        .catch((err) => {
          console.error('Error fetching product details:', err);
          setLoading(false);
        });
    };




  if (loading) {
    return <div>Loading...</div>; // Add a loader or message while data is fetching
  }
  const handleShowColor = () => {
     setShowColorModal(true);
   };

   const handleCloseColor = () => setShowColorModal(false);



  // Handler to set carousel images based on selected color option
  const handleColorClick = (option) => {
    const selectedImages = option.displayImages?.length ? option.displayImages : option.images;
    setCarouselImages(selectedImages || []); // Set carousel images to selected color option images
  };

  const handleShow = () => {
     setFormData({
       name: productDetail.name,
       status: 'ACTIVE',
       colorOptions:numberOfColors,
       offerPrice: productDetail.offerPrice,
       basePrice:productDetail.basePrice,
       clientRating: productDetail.clientRating,
       fabric: productDetail.fabric?.name,
       gsm: productDetail.gsm,
       mrp: productDetail.mrp,
       ratingCount: productDetail.ratingCount,
       moq: productDetail.moq,
       availableSizes: productDetail.availableSizes?.join(', ') || '',
       productDescription: productDetail.productDescription.join('\n') || '',
       customizeOptions: productDetail.customizeOptions.join('\n') || '',
       moreInfo: productDetail.moreInfo.join('\n') || '',
     });
     setShowModal(true);
   };

   const handleClose = () => setShowModal(false);


   const handleInputChange = (e) => {
     const { name, value } = e.target;
     setFormData((prevState) => ({
       ...prevState,
       [name]: value,
     }));
   };
   const handleColorChange = (e) => {
  const { name, value } = e.target;
  setColorData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};


   const handleEditSubmit = (id) => {
  const requestBody = {
    name: formData.name,
    status: formData.status,
    colorOptions:formData.colorOptions,
    offerPrice: formData.offerPrice,
    basePrice:formData.basePrice,
    clientRating: formData.clientRating,
    fabric: { name: formData.fabric },
    gsm: formData.gsm,
    mrp: formData.mrp,
    ratingCount: formData.ratingCount,
    moq: formData.moq,
    availableSizes: formData.availableSizes.split(',').map((size) => size.trim()),
    productDescription: formData.productDescription.split('\n').map((desc) => desc.trim()),
    customizeOptions: formData.customizeOptions.split('\n').map((option) => option.trim()),
    moreInfo: formData.moreInfo.split('\n').map((info) => info.trim()),
  };

  PutToServer(fullPath.replace("/", ""), `product/${id}`, requestBody)
    .then((res) => {
      if (res.status === 200) {
        // Update the product details in the state directly
        setProductDetail((prevState) => ({
          ...prevState,
          name: formData.name,
          status: formData.status,
          colorOptions:formData.colorOptions,
          offerPrice: formData.offerPrice,
          basePrice:formData.basePrice,
          clientRating: formData.clientRating,
          fabric: { name: formData.fabric },
          gsm: formData.gsm,
          mrp: formData.mrp,
          ratingCount: formData.ratingCount,
          moq: formData.moq,
          availableSizes: formData.availableSizes.split(',').map((size) => size.trim()),
          productDescription: formData.productDescription.split('\n').map((desc) => desc.trim()),
          customizeOptions: formData.customizeOptions.split('\n').map((option) => option.trim()),
          moreInfo: formData.moreInfo.split('\n').map((info) => info.trim()),
        }));
        handleClose();  // Close the modal after successful update
      } else {
        console.error('Failed to update product:', res);
      }
    })
    .catch((error) => {
      console.error('Error while updating product:', error);
    });
};

const handleAdd = (id) => {
  event.preventDefault();

  const data = {
    name: colorData.name,
    status: colorData.status,
    color:colorData.color,
    colorName: colorData.colorName,
    colorIndex: colorData.colorIndex,
    shortName: colorData.shortName,

  };

  sendData(fullPath.replace("/", ""), `product/${id}/item`, data)
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.error(err);
    });
};
  return (
    <>
    <Modal size="lg" show={showColorModal} onHide={handleCloseColor}>
  <Modal.Header closeButton>
    <Modal.Title>Add Color</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Row>
        <Col md={6}>
        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={colorData.name}
            onChange={handleColorChange}
          />
        </Form.Group>

        {/* Status */}
        <Form.Group className="mb-3">
          <Form.Label>Color Name</Form.Label>
          <Form.Control
            type="text"
            name="colorName"
            value={colorData.colorName}
              onChange={handleColorChange}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Color Code</Form.Label>
          <Form.Control
            type="text"
            name="color"
            value={colorData.color}
              onChange={handleColorChange}
          />
        </Form.Group>


        </Col>

        <Col md={6}>

                {/* Offer Price */}
                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    type="text"
                    name="status"
                    value={colorData.status}
                      onChange={handleColorChange}
                  />
                </Form.Group>

                {/* Client Rating */}
                <Form.Group className="mb-3">
                  <Form.Label>Short Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="shortName"
                    value={colorData.shortName}
                      onChange={handleColorChange}
                  />
                </Form.Group>

                {/* Fabric */}
                <Form.Group className="mb-3">
                  <Form.Label>Order Index</Form.Label>
                  <Form.Control
                    type="number"
                    name="orderIndex"
                    value={colorData.orderIndex}
                      onChange={handleColorChange}
                  />
                </Form.Group>

        </Col>
      </Row>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="primary" onClick={() => handleAdd(productDetail.id)}>
      Add
    </Button>
  </Modal.Footer>
</Modal>

    <Modal size="xl" show={showModal} onHide={handleClose}>
  <Modal.Header closeButton>
    <Modal.Title>Edit Product Details</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Row>
        <Col md={5}>
          {/* Product Name */}
          <Form.Group className="mb-3">
            <Form.Label>Product Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* Status */}
          <Form.Group className="mb-3">
            <Form.Label>Status</Form.Label>
            <Form.Control
              type="text"
              name="status"
              value={formData.status}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Available Color</Form.Label>
            <Form.Control
              type="text"
              name="colorOptions"
              value={formData.colorOptions}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* Offer Price */}
          <Form.Group className="mb-3">
            <Form.Label>Offer Price</Form.Label>
            <Form.Control
              type="number"
              name="offerPrice"
              value={formData.offerPrice}
              onChange={handleInputChange}
            />
          </Form.Group>
            {/* Base Price */}
          <Form.Group className="mb-3">
            <Form.Label>Base Price</Form.Label>
            <Form.Control
              type="number"
              name="basePrice"
              value={formData.basePrice}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* Client Rating */}
          <Form.Group className="mb-3">
            <Form.Label>Client Rating</Form.Label>
            <Form.Control
              type="number"
              name="clientRating"
              value={formData.clientRating}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* Fabric */}
          <Form.Group className="mb-3">
            <Form.Label>Fabric</Form.Label>
            <Form.Control
              type="text"
              name="fabric"
              value={formData.fabric}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* GSM */}
          <Form.Group className="mb-3">
            <Form.Label>GSM</Form.Label>
            <Form.Control
              type="text"
              name="gsm"
              value={formData.gsm}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* MRP */}
          <Form.Group className="mb-3">
            <Form.Label>MRP</Form.Label>
            <Form.Control
              type="number"
              name="mrp"
              value={formData.mrp}
              onChange={handleInputChange}
            />
          </Form.Group>


          {/* Rating Count */}
          <Form.Group className="mb-3">
            <Form.Label>Rating Count</Form.Label>
            <Form.Control
              type="number"
              name="ratingCount"
              value={formData.ratingCount}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* MOQ */}
          <Form.Group className="mb-3">
            <Form.Label>MOQ</Form.Label>
            <Form.Control
              type="number"
              name="moq"
              value={formData.moq}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* Available Sizes */}
          <Form.Group className="mb-3">
            <Form.Label>Available Sizes</Form.Label>
            <Form.Control
              type="text"
              name="availableSizes"
              value={formData.availableSizes}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>

        <Col md={7}>
          {/* Product Description */}
          <Form.Group className="mb-3">
            <Form.Label>Product Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={8}
              name="productDescription"
              value={formData.productDescription}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* Customize Options */}
          <Form.Group className="mb-3">
            <Form.Label>Customize Options</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              name="customizeOptions"
              value={formData.customizeOptions}
              onChange={handleInputChange}
            />
          </Form.Group>

          {/* More Info */}
          <Form.Group className="mb-3">
            <Form.Label>More Info</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              name="moreInfo"
              value={formData.moreInfo}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleClose}>
      Close
    </Button>
    <Button variant="primary" onClick={() => handleEditSubmit(productDetail.id)}>
      Update
    </Button>
  </Modal.Footer>
</Modal>

      <Card className="mb-3">
        <Card.Body>
          <Row>
            {/* Product Images */}
            <Col lg={3} className="mb-4 mb-lg-0">
              {carouselImages.length > 0 ? (
                <Carousel>
                  {carouselImages.map((image, index) => (
                    <Carousel.Item key={index}>
                      <img
                        className="d-block w-100"
                        src={image.imageDesktop}
                        alt={`Product Image ${index + 1}`}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              ) : (
                <p>No images available for this product.</p>
              )}
            </Col>

            {/* Product Details */}
            <Col lg={9}>
              <h4>{productDetail.name}</h4>
              <h5 className="text-success">₹{productDetail.offerPrice}</h5>
                <p className="text-muted text-decoration-line-through">₹{productDetail.mrp}</p>
                <Badge bg="warning" text="dark">
                  <FontAwesomeIcon icon={faStar} /> {productDetail.clientRating} ({productDetail.ratingCount} ratings)
                </Badge>
              {/* Fabric and GSM */}
              <div className="mt-3">
                    <strong>Fabric:</strong> {productDetail.fabric.name}
                    <br />
                    <strong>GSM:</strong> {productDetail.gsm}
                    <br />
                    <strong>colorOptions:</strong> {numberOfColors}
                    <br />
                  <strong>MOQ:</strong> {productDetail.moq}
                      <br />
                  <strong>Base Price</strong> {productDetail.basePrice}
              </div>
              <div>
              <h3>Available Sizes</h3>
              <div className="btn-group" role="group" aria-label="Available Sizes">
                {productDetail.availableSizes.map((size, index) => (
                  <button
                    key={index}
                    type="button"
                    className="btn btn-outline-primary"
                  >
                    {size}
                  </button>
                ))}
              </div>
            </div>

              <IconButton
                iconClassName="fs--2 me-1"
                variant="falcon-default"
                size="sm"
                icon="pencil-alt"
                onClick={() => handleShow()}
                className="position-absolute"
                style={{top:"0",right:"0",backgroundColor:"transparent",border:"none",boxShadow:"none"}}
              >
             Edit
              </IconButton>
            </Col>
          </Row>
          {/* Available Colors */}
          <div className="product-options mt-2">
            <div className="d-flex flex-row overflow-auto">
            {productDetail?.productOptions?.map((option) => (
                  <div
                    key={option.id}
                    className="product-option me-3"
                    onClick={() => handleColorClick(option)} // Set images on click
                  >
                    <div className="option-images">
                      {/* Check if displayImages is an array with at least one item */}
                      {Array.isArray(option.displayImages) && option.displayImages.length > 0 ? (
                        <img
                          src={option.displayImages[0].imageDesktop}
                          alt={option.colorName || 'Color option'}
                          aria-label={option.colorName || 'Color option'}
                          style={{ width: '50px', cursor: 'pointer' }}
                        />
                      ) : (
                        // Fallback to check if images is an array with at least one item
                        Array.isArray(option.images) && option.images.length > 0 && (
                          <img
                            src={option.images[0].imageDesktop}
                            alt={option.colorName || 'Color option'}
                            aria-label={option.colorName || 'Color option'}
                            style={{ width: '50px', cursor: 'pointer' }}
                          />
                        )
                      )}
                    </div>
                    <p className="text-center" style={{fontSize:"12px"}}>{option.colorName || 'Default Color'}</p>
                  </div>
                ))}
                <IconButton
                  variant="falcon-primary"
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                  onClick={() => handleShowColor()}
                  style={{top:"0",right:"0",backgroundColor:"transparent",border:"none",boxShadow:"none"}}
                >
                  Add Color
                </IconButton>
            </div>
          </div>
          <div className="overflow-hidden mt-4">
            <Tab.Container defaultActiveKey="description">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link
                    eventKey="description"
                    className="ps-0 cursor-pointer outline-none"
                  >
                    Description
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="specifications"
                    className="px-2 px-md-3 cursor-pointer outline-none"
                  >
                    Specifications
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="reviews"
                    className="px-2 px-md-3 cursor-pointer outline-none"
                  >
                    Reviews
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="description">
                <div>
                <h3>Product Description</h3>
                <ul className="list-group">
                  {productDetail.productDescription.map((item, index) => (
                    <li key={index} >
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
              <div>
              <h3>Product CustomizeOptions</h3>
              <ul className="list-group">
                {productDetail.customizeOptions.map((item, index) => (
                  <li key={index} >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            <div>
            <h3>More Info</h3>
            <ul className="list-group">
              {productDetail.moreInfo.map((item, index) => (
                <li key={index} >
                  {item}
                </li>
              ))}
            </ul>
          </div>

                </Tab.Pane>
                <Tab.Pane eventKey="specifications">
                  <Table className="fs--1 mt-3">

                  </Table>
                </Tab.Pane>
                <Tab.Pane eventKey="reviews">
                  <Row className="mt-3">
                    <Col lg={6} className="mb-4 mb-lg-0">

                    </Col>
                    <Col lg={6} className="ps-lg-5">
                      <Form>
                        <h5 className="mb-3">Write your Review</h5>
                        <div className="mb-3">
                          <Form.Label className="mb-0">Rating:</Form.Label>
                          <StarRating className="d-block fs-3" rating={0} />
                        </div>
                        <div className="mb-3">
                          <Form.Label>Name:</Form.Label>
                          <Form.Control type="text" />
                        </div>
                        <div className="mb-3">
                          <Form.Label>Email:</Form.Label>
                          <Form.Control type="text" />
                        </div>
                        <div className="mb-3">
                          <Form.Label>Review:</Form.Label>
                          <Form.Control as="textarea" rows="3" type="text" />
                        </div>
                        <Button variant="primary" type="submit">
                          Submit
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default ProductName;
