import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import React, { useState,useEffect } from 'react';
import { Card, Dropdown,Row,Col,Form,Button } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import ProductHeader from './ProductHeader';
import { ResponseFromServer } from 'helpers/utils';
import { useNavigate } from 'react-router-dom';




const ProductList = () =>  {

  const navigate = useNavigate();



    const columns =  [
      {
        accessor: 'productId',
        Header: 'Product Id',
        headerProps: {
        className: 'text-start'
        },
        cellProps: {
        className: 'text-start'
        }
      },
      {
      accessor: 'name',
      Header: 'Name',
      headerProps: { className: 'pe-1' },
      cellProps: { className: 'py-2' },
      Cell: ({ row }) => {
        const handleClick = () => {
          const pageName = row.original?.pageName;
          if (!pageName) {
            console.error("Page name is missing in row data");
            return;
          }

          // Navigate to the product details page with a query parameter
          navigate(`/product/product-name?productName=${encodeURIComponent(pageName)}`);
        };

        return (
          <strong>
            <span
              className="text-info"
              onClick={handleClick}
              style={{ cursor: 'pointer' }}
            >
              {row.original.name}
            </span>
          </strong>
        );
      },
    },
    {
      accessor: 'colorOptions',
      Header: 'Available Color',
      headerProps: {
      className: 'text-start'
      },
      cellProps: {
      className: 'text-start'
    },Cell: ({ row }) => (
      <div>
      <div>
          {row.original.colorOptions}
      </div>

      </div>
    )
    },
    {
      accessor: 'offerPrice',
      Header: 'Offer Price',
      headerProps: {
      className: 'text-start'
      },
      cellProps: {
      className: 'text-start'
      }
    },

    {
      accessor: 'mrp',
      Header: 'Mrp',
      headerProps: {
      className: 'text-start'
      },
      cellProps: {
      className: 'text-start'
      }
    },
    {
    accessor: 'status',
    Header: 'Status',
    headerProps: {
      className: 'text-start'
    },
    Cell: ({ value }) => (
      <span className={value === 'ACTIVE' ? 'text-success' : 'text-warning'}>
        {value ? value.charAt(0).toUpperCase() + value.slice(1) : 'INACTIVE'}
      </span>
    ),
    cellProps: {
      className: 'text-start'
    }
  }



    ];


  const [productFilter ,setProductFilter] =useState([])



  return (

    <AdvanceTableWrapper
      columns={columns}
      data={productFilter}
      sortable
      pagination
      perPage={500}
    >

      <ProductHeader setProductFilter={setProductFilter}/>
      <Card className="mb-3">
        <Card.Header>
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Products</h5>
          </Col>
          </Row>

        </Card.Header>

        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>

          <AdvanceTablePagination table />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default ProductList;
