import React, { useState,useEffect } from 'react';
import { Card, Col, Row, Form, Modal, Button, Badge } from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { ResponseFromServer } from 'helpers/utils';

const RequirementHeader = (props) => {
  const [requirementDetails ,setRequirementDetails] =useState([])

  useEffect(() => {
    handleRequirementDetails();
  }, []);


  const handleRequirementDetails = () => {
    ResponseFromServer('requirements/requirement-list', `inventory/required-stock`).then((res) => {
      setRequirementDetails(res.data);
      props.setRequirementFilter(res.data);
      console.log(res.data)
    });
  };

console.log(requirementDetails)


  return (
    <>
    

    </>
  );
};

export default RequirementHeader;
